<template>
  <v-row class="campaigns">
    <v-col cols="12" class="pa-0">
      <div class="title justify-space-between align-baseline">
        <div class="white--text text-uppercase text-sm-h5 text-h6 font-weight-bold">Campaigns History</div>
        <div class="d-flex title-input">
          <v-text-field
            v-model="search"
            placeholder="Search by name, status"
            prepend-inner-icon="mdi-magnify"
            :append-icon="search ? 'mdi-close' : ''"
            @click:append="clearSearch"
            outlined
            dense
            required
            color="input"
            @change="(page = 1), getData()"
          >
          </v-text-field>
          <v-btn to="/campaigns-new" class="white--text ml-5" width="184" height="48" color="secondary">Start new</v-btn>
        </div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card v-else elevation="5" max-width="1070" min-height="260" class="mx-lg-auto mx-4 mx-sm-15">
        <v-card flat height="70vh" min-height="260" v-if="nothing" class="pa-3 d-flex align-center justify-center flex-column">
          <v-img max-height="124" contain src="@/assets/img/notFound.svg"></v-img>
          <div class="text-center text-h5 font-weight-bold mt-8">Looks like there are not campaigns according to your request</div>
        </v-card>
        <v-card
          flat
          height="70vh"
          min-height="260"
          v-else-if="block || !data.result.length"
          class="pa-3 d-flex align-center justify-center flex-column"
        >
          <v-img max-height="124" contain src="@/assets/img/notFound.svg"></v-img>
          <div class="text-center text-h6 font-weight-bold mt-8">Looks like you have not started any mailing campaign</div>
          <!-- <div class="text-center opasity--text font-weight-regular mt-2">Please, contact support to resolve this issue</div> -->
          <div class="text-center mt-5">
            <v-btn color="primary" height="48" width="184" elevation="0" to="/campaigns-new">Start campaign</v-btn>
          </div>
        </v-card>
        <div v-else>
          <v-data-table
            class="p-table"
            hide-default-footer
            fixed-header
            :headers="headers"
            :items="data.result"
            item-key="name"
            :items-per-page="Number(itemsPerPage)"
            :height="`calc(100vh - 230px)`"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
          >
            <template v-slot:header.name>
              Name
              <v-btn @click="toggleOrder" icon class="btn-sort" :class="sortDesc ? 'input white--text' : ''">
                <v-icon>{{ sortDesc ? 'mdi-sort-ascending' : 'mdi-sort-descending' }}</v-icon>
              </v-btn>
            </template>
            <template v-slot:header.status>
              Status
              <v-menu bottom :close-on-content-click="true" transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :class="status == 'all' ? 'opasity--text white' : 'white--text input'"
                    class="pa-0"
                    height="24"
                    min-width="24"
                    x-small
                    text
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-filter mdi-18px</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-hover v-slot="{ hover }" v-for="item in statusList" :key="item.name">
                    <div
                      @click="valueSelect(item.name)"
                      class="link px-3 py-2 m-1"
                      :class="[status == item.name ? 'gray lighten-1' : '', hover ? 'opasity lighten-4' : '']"
                    >
                      {{ item.title }}
                    </div>
                  </v-hover>
                </v-card>
              </v-menu>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id" height="52" class="link" @click="openItem(item.id)">
                  <td>{{ item.name }}</td>
                  <td class="d-sm-table-cell d-none">{{ item.execution_date ? new Date(item.execution_date).toLocaleString() : '-' }}</td>
                  <td>{{ item.messages_count }}</td>
                  <td>{{ item.messages_sent }}</td>
                  <td>{{ item.messages_delivered }}</td>
                  <td>
                    <div class="ml-auto status" :class="getColorClass(item.status)">{{ item.status }}</div>
                  </td>
                </tr>
              </tbody>
            </template>
            <template v-slot:footer>
              <v-divider></v-divider>
              <v-row class="mt-2 pb-2 px-10" align="center" justify="end">
                <span class="grey--text">Rows per page:</span>
                <div class="perPageSelect">
                  <v-select
                    v-model="itemsPerPage"
                    :items="itemsPerPageArray"
                    menu-props="auto"
                    hide-details
                    single-line
                    dense
                    @change="getData"
                  ></v-select>
                </div>
                <v-btn icon color="secondary" class="mr-1" @click="formerPage">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span class="mx-4 grey--text">{{ page }} of {{ data.pages }}</span>
                <v-btn icon color="secondary" class="ml-1" @click="nextPage">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      search: this.$route.query.src || '',
      itemsPerPage: Number(this.$route.query.page_size) || 10,
      page: Number(this.$route.query.page) || 1,
      status: this.$route.query.status || 'all',
      sortBy: 'name',
      load: true,
      sortDesc: false,
      block: true,
      nothing: false,
      headers: [
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Execution Date', value: 'execution_date', class: 'd-sm-table-cell d-none', sortable: false },
        { text: 'Planned', value: 'messages_count', sortable: false },
        { text: 'Sent', value: 'messages_sent', sortable: false },
        { text: 'Delivered', value: 'messages_delivered', sortable: false },
        { text: 'Status', align: 'end', value: 'status', sortable: false },
      ],
      statusError: null,
    };
  },
  mounted() {
    this.getData();
    this.$store.dispatch('getPagination');
    this.$store.dispatch('getCampaignFilter');
  },
  methods: {
    getColorClass(block) {
      if (block == 'Moderation') {
        return 'warning';
      } else if (block == 'Blocked') {
        return 'error';
      } else if (block == 'Submitted') {
        return 'blue lighten-1';
      } else if (block == 'Started') {
        return 'success';
      } else if (block == 'Finished') {
        return 'input';
      } else if (block == 'Payment failed') {
        return 'gray';
      } else if (block == 'Scheduled') {
        return 'blue lighten-1';
      }
    },
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    toggleOrder() {
      this.sortDesc = !this.sortDesc;
    },
    valueSelect(status) {
      this.status = status;
      this.getData();
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.getData();
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    openItem(id) {
      this.$router.push({ name: 'Campaigns item', params: { campaignsId: id } });
    },
    async getData() {
      await this.$store
        .dispatch('getCampaignsList', {
          pageSize: this.itemsPerPage,
          search: this.search,
          page: this.page,
          status: this.status,
        })
        .then(() => {
          this.nothing = false;
          this.block = false;
        })
        .catch((e) => {
          this.getDataError(e);
        })
        .finally(() => {
          this.load = false;
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (e.response.data.error[0] === 'account__not_found') {
        this.$store.dispatch('setCampaignsList', {});
        this.block = true;
      } else if (e.response.data.error[0] === 'result__not_found') {
        this.$store.dispatch('setCampaignsList', {});
        this.nothing = true;
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.campaignsList;
    },
    itemsPerPageArray() {
      return this.$store.getters.pagination;
    },
    statusList() {
      return this.$store.getters.campaignFilter;
    },
  },
  destroyed() {
    this.$store.dispatch('setCampaignsList', {});
  },
};
</script>

<style lang="scss">
.campaigns {
  .btn-sort {
    width: 24px;
    height: 24px;
    border-radius: 4px;

    .v-icon {
      font-size: 16px;
    }
  }
  .status {
    line-height: 24px;
    color: white;
    padding: 0 16px;
    width: max-content;
    border-radius: 24px;
  }
}
</style>
